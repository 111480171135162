import { usersConstants } from '../constants/users';
import { concat, uniqBy } from 'lodash';

const initialState = {
    products: []
}

export function users(state = initialState, action = {}){
    switch (action.type) {
        case usersConstants.GET_USERS_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case usersConstants.GET_USERS_SUCCESS:
            return {
                
                ...state,
                listLoading: false,
                items: action.users,
            };
        case usersConstants.GET_USERS_FAILURE:
            return {
                ...state,
                listLoading: false,
                error: action.error
            };
            
        case usersConstants.GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
                user: null
            };
        case usersConstants.GET_USER_SUCCESS:
            
            const user = action.overwrite ? action.user : state.user;
            
            return {
                
                ...state,
                loading: false,
                user
            };
        case usersConstants.GET_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };            
            
        case usersConstants.ADD_USER_REQUEST:
            return {
                ...state,
                loading: true,
                userSaved: false
            };
        case usersConstants.ADD_USER_SUCCESS:
            return {
                
                ...state,
                loading: false,
                userSaved: true
            };
        case usersConstants.ADD_USER_FAILURE:
            return {
                ...state,
                loading: false,
                userSaved: false
            };
            
        case usersConstants.DELETE_USERS_REQUEST:
            return {
                ...state,
                listLoading: true,
            };
        case usersConstants.DELETE_USERS_SUCCESS:
            return {
                
                ...state,
                listLoading: false,
            };
        case usersConstants.DELETE_USERS_FAILURE:
            return {
                ...state,
                listLoading: false,
            };
            
        case usersConstants.GET_IMPRINTS_REQUEST:
            return {
                ...state,
                imprintsLoading: true,
                imprints: null
            };
        case usersConstants.GET_IMPRINTS_SUCCESS:
            return {
                
                ...state,
                imprintsLoading: false,
                imprints: action.imprints,
            };
        case usersConstants.GET_IMPRINTS_FAILURE:
            return {
                ...state,
                imprintsLoading: false,
                error: action.error
            };

            
        case usersConstants.GET_ARTISTS_REQUEST:
            return {
                ...state,
                artistsLoading: true,
                artists: null
            };
        case usersConstants.GET_ARTISTS_SUCCESS:
            return {
                
                ...state,
                artistsLoading: false,
                artists: action.artists,
            };
        case usersConstants.GET_ARTISTS_FAILURE:
            return {
                ...state,
                artistsLoading: false,
                error: action.error
            };            
          
            
        case usersConstants.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                productsLoading: true,
                products: []
            };
        case usersConstants.GET_PRODUCTS_SUCCESS:
            // let products = concat(state.products, action.products) ;
            //products = uniqBy(products, 'artist_id');
            return {
                
                ...state,
                productsLoading: false,
                products: action.products
            };
        case usersConstants.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                productsLoading: false,
                error: action.error
            };
            
        case usersConstants.GET_CAMPAIGNS_REQUEST:
            return {
                ...state,
                campaignsLoading: true
            };
        case usersConstants.GET_CAMPAIGNS_SUCCESS:
            return {
                
                ...state,
                campaignsLoading: false,
                campaigns: action.campaigns
            };
        case usersConstants.GET_CAMPAIGNS_FAILURE:
            return {
                ...state,
                campaignsLoading: false,
                error: action.error
            };
            
        default:
            return state
    }
}